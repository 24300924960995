import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const MsmeForm = ({ msmeFormData, setMsmeFormData, msmeErrors }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setMsmeFormData({ ...msmeFormData, [name]: value });
  };

  const handleImageUpload = async (e) => {
    console.log("1");
    let url = backendUrl + "/Msme_inspections/uploadImages";

    const receivedFiles = e.target.files;
    try {
      const body = new FormData();
      for (let i = 0; i < receivedFiles.length; i++) {
        body.append("files", receivedFiles[i]);
      }
      const res = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File uploaded");
      }
    } catch (error) {
      toast.error("Error upload file");
    }
    console.log("2");

    const fileNames = Array.from(receivedFiles).map((file) => file.name);
    setMsmeFormData((prevData) => ({
      ...prevData,
      inspectionImages: [...prevData.inspectionImages, ...fileNames],
    }));
  };
  console.log(msmeFormData?.inspectionImages);
  return (
    <>
      <TextField
        label="Name of Person Interviewed"
        name="name"
        sx={{ mt: 1 }}
        value={msmeFormData.name}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.name}
        helperText={msmeErrors.name}
      />
      <TextField
        label="Comments"
        name="comments"
        value={msmeFormData.comments}
        onChange={handleInputChange}
        fullWidth
        multiline
        required
        rows={4}
        error={!!msmeErrors.comments}
        helperText={msmeErrors.comments}
      />

      <TextField
        label="Inspection Conducted By"
        name="inspectionBy"
        value={msmeFormData.inspectionBy}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.inspectionBy}
        helperText={msmeErrors.inspectionBy}
      />
      <Stack gap={1}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="residence-image-upload"
          type="file"
          onChange={(e) => handleImageUpload(e)}
          multiple
        />
        <label htmlFor="residence-image-upload">
          <Button sx={{ color: "white !important" }} variant="contained" component="span">
            Upload Images
          </Button>
        </label>
      </Stack>
      {msmeFormData?.inspectionImages?.length > 0 && (
        <TableContainer sx={{ boxShadow: 0, border: "1px solid", overflowX: "visible" }}>
          <Table>
            <TableRow>
              <TableCell>Sr_No</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>

            <TableBody>
              {msmeFormData.inspectionImages.map((image, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{image}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default MsmeForm;
