import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import styles from "./Inspections.module.css"; // Importing module.css
import axios from "axios";
import { styled } from "@mui/material/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Inspections = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const branch_code = sessionStorage.getItem("branch_code");
  const [products, setProducts] = useState([]);
  const [inspectedAccounts, setInspectedAccounts] = useState([]);
  const [inspectionPendingAccounts, setInspectionPendingAccounts] = useState([]);

  // Watch 'type' field to enable/disable 'product' field
  const selectedType = watch("type");

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await axios.get(
      `${backendUrl}/account/notInspected?acctType=${data.product}&inspectionType=${data.type}&BranchCode=${branch_code}`
    );
    setInspectedAccounts(res.data.inspected || []);
    setInspectionPendingAccounts(res.data.notInspected || []);
    setLoading(false);
  };

  const getProducts = async () => {
    const url = backendUrl + "/account/accountTypes";
    try {
      const res = await axios.get(url);
      setProducts(res.data);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  // Reset the product field when the type is changed
  useEffect(() => {
    resetField("product");
  }, [selectedType]);

  // view perticular account
  const navigate = useNavigate();
  const handleView = (number, category) => {
    console.log(category + "category");
    if (!category && category != 0) {
      return;
    }
    let visitUrl = null;
    if (category == "01") {
      visitUrl = `/standard/summary/dashboard/${number}`;
    } else if (category == "SMA0" || category == "SMA1" || category == "SMA2") {
      visitUrl = `/sma/summary/dashboard/${number}`;
    } else {
      visitUrl = `/npa/summary/dashboard/${number}`;
    }
    navigate(visitUrl);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? <Loader /> : null}

      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Type Field */}
          <div className={styles.formGroup}>
            <TextField
              id="type"
              label="Type"
              select
              fullWidth
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("type", { required: "Type is required" })}
              error={!!errors.type}
              helperText={errors?.type?.message}
            >
              <option value="">Select type...</option>
              <option value="MSME">MSME</option>
              <option value="psegment">P-Segment</option>
            </TextField>
          </div>

          {/* Product Field */}
          <div className={styles.formGroup}>
            <TextField
              id="product"
              label="Product"
              select
              fullWidth
              variant="outlined"
              {...register("product", {
                required: selectedType ? "Product is required" : false,
              })}
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.product}
              helperText={errors?.product?.message}
              disabled={!selectedType || products.length === 0}
            >
              <option value="">Select product...</option>
              {products.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.code} {option.name}
                </option>
              ))}
            </TextField>
          </div>

          {/* Submit Button */}
          <Button
            className="ActionButtonsColor"
            disabled={loading}
            variant="contained"
            type="submit"
            fullWidth
          >
            Search
          </Button>
        </form>
      </div>

      {/* Tables */}
      {inspectedAccounts.length > 0 && (
        <div>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Inspected Accounts:
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Place
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspectedAccounts.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.city}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {inspectionPendingAccounts.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Inspection Pending Accounts:
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Place
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspectionPendingAccounts.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.city}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Inspections;
