import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useLocation } from "react-router-dom";
// date converter library
import moment from "moment";
// toast messages
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/Loader";
import MsmeForm from "./Inpections/MsmeForm";
import PSegmentForm from "./Inpections/PSegmentForm";

// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Inspection() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // MODAL HANDLERS AND STATE
  const [modalOpen, setModalopen] = useState(false);
  const [msmeModalOpen, setMsmeModalopen] = useState(false);
  const createNewInspection = (type) => {
    if (type === "pSegment") {
      setModalopen(true);
    } else if (type === "msme") {
      setMsmeModalopen(true);
    }
  };
  // Initial form data
  const initialFormData = {
    name: "",
    inspectionBy: "",
    residenceAddress: "",
    residenceVisited: "no",
    residenceObservations: "",
    workPlaceAddress: "",
    workPlaceVisited: "no",
    workPlaceObservations: "",
    residenceImages: [],
    workPlaceImages: [],
    residenceGeoLocation: "",
    workPlaceGeoLocation: "",
  };
  // {
  //   "accountNumber": "123456789",
  //   "name": "sdvdvv",
  //   "inspectionBy": "drvdvdv",
  //   "comments": "Some comments",
  //    "inspectionImages": ["logo-varachha.jpg"]

  // }
  const initialMsmeData = {
    name: "",
    comments: "",
    inspectionBy: "",
    inspectionImages: [],
  };

  // FORM DATA HANDLERS AND STATES
  const [formData, setFormData] = useState(initialFormData);
  const [msmeFormData, setMsmeFormData] = useState(initialMsmeData);

  // validations
  const [msmeErrors, setMsmeErrors] = useState({});
  const validateMsmeForm = () => {
    const newErrors = {};
    let isValid = true;
    if (!msmeFormData.comments.trim()) {
      newErrors.comments = "Provide some comments";
      isValid = false;
    }
    if (!msmeFormData.name.trim()) {
      newErrors.name = "Person name is required";
      isValid = false;
    }
    if (!msmeFormData.inspectionBy.trim()) {
      newErrors.inspectionBy = "Provide Inspected By";
      isValid = false;
    }
    setMsmeErrors(newErrors);
    return isValid;
  };
  const [pSegmentErrors, setPsegmentErrors] = useState({});
  const validatePsegmentForm = () => {
    const newErrors = {};
    let isValid = true;
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (formData.residenceVisited === "yes") {
      if (!formData.residenceAddress.trim()) {
        newErrors.residenceAddress = "Provide residence address";
        isValid = false;
      }
      if (!formData.residenceObservations.trim()) {
        newErrors.residenceObservations = "Provide Observation";
        isValid = false;
      }
    }
    if (!formData.residenceVisited.trim()) {
      newErrors.residenceVisited = "Select Option";
      isValid = false;
    }
    if (formData.workPlaceVisited === "yes") {
      if (!formData.workPlaceAddress.trim()) {
        newErrors.workPlaceAddress = "Provide Workplace Address";
        isValid = false;
      }
      if (!formData.workPlaceObservations.trim()) {
        newErrors.workPlaceObservations = "Provide Observation";
        isValid = false;
      }
    }
    if (!formData.workPlaceVisited.trim()) {
      newErrors.workPlaceVisited = "Select Option";
      isValid = false;
    }
    setPsegmentErrors(newErrors);
    return isValid;
  };

  // Handler for form submission
  const handleSubmit = async () => {
    const valid = validatePsegmentForm();
    if (valid) {
      const pSegment = process.env.REACT_APP_BACKEND_URL + "/Psegement_inspections/create";
      try {
        const res = await axios.post(pSegment, { ...formData, accountNumber: id });
        if (res.status === 200) {
          setFormData(initialFormData);
          toast.success("Inspection Saved");
          getInspectionHistory();
        }
      } catch (error) {
        toast.error("Unable to save inspection");
      }
      setFormData(initialFormData);
      pSegmentFormModalClose();
    }
  };
  const handleMsmeSubmit = async () => {
    const valid = validateMsmeForm();
    if (valid) {
      const msmeUrl = backendUrl + "/Msme_inspections/create";
      try {
        const res = await axios.post(msmeUrl, { ...msmeFormData, accountNumber: id });
        if (res.status === 200) {
          setMsmeFormData(initialMsmeData);
          toast.success("Inspection Saved");
          getInspectionHistory();
        }
      } catch (error) {
        console.log(error.message);
        toast.error("Unable to save inspection");
      }
      msmeFormModalClose();
    }
  };

  // Handler for closing the modal
  const pSegmentFormModalClose = () => {
    setFormData(initialFormData);
    setModalopen(null);
    setPsegmentErrors({});
  };
  const msmeFormModalClose = () => {
    setMsmeFormData(initialMsmeData);
    setMsmeModalopen(null);
    setMsmeErrors({});
  };

  // ----------------------

  const getInspectionHistory = async () => {
    const url = backendUrl + `/Inspections/getAll?accountNumber=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInspectionHistory();
  }, []);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  function truncateText(text, limit) {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Inspection History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    A/C No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Inspection Type
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Location
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Summary
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell className="NewTableCells" align="left">
                        {row?.accountNumber ? row?.accountNumber : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.followUpType ? row?.followUpType : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.date ? moment(row?.date).format("DD-MM-YYYY hh:mm") : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.location ? row?.location : "---"}
                      </TableCell>
                      <TableCell
                        className="NewTableCells"
                        align="left"
                        sx={{ maxWidth: "200px", overflow: "hidden" }}
                      >
                        <Tooltip title={row.summary}>
                          <div style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                            {truncateText(row.summary, 30) || "---"}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.report ? (
                          <a href={row.report} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack justifyContent="flex-end" my={1} gap={1} flexDirection="row">
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={() => createNewInspection("pSegment")}
              sx={{ color: "white !important" }}
            >
              p-segment
            </Button>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={() => createNewInspection("msme")}
              sx={{ color: "white !important" }}
            >
              MSME
            </Button>
          </Stack>
        </MDBox>
      )}
      {/* p-segment */}
      <Dialog
        open={Boolean(modalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={pSegmentFormModalClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new inspection"}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <PSegmentForm
            formData={formData}
            backendUrl={backendUrl}
            setFormData={setFormData}
            pSegmentErrors={pSegmentErrors}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={pSegmentFormModalClose}>Cancel</Button>
          {/* <Button onClick={handleGenerateReport}>Generate Report</Button> */}
        </DialogActions>
      </Dialog>
      {/* msme form */}
      <Dialog
        open={Boolean(msmeModalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={msmeFormModalClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new inspection"}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <MsmeForm
            msmeErrors={msmeErrors}
            setMsmeFormData={setMsmeFormData}
            msmeFormData={msmeFormData}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMsmeSubmit}>Submit</Button>
          <Button onClick={msmeFormModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
